import React, { Component } from 'react';
import GoogleMaps from './GoogleMaps';
import Autocomplete from 'react-google-autocomplete';
import './Shipping.css';
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import util from '../util'


// const { compose, withProps, lifecycle } = require("recompose");
// const {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   DirectionsRenderer,
// } = require("react-google-maps");





class Shipping extends Component {
    constructor(props) {
        super(props);

        this.state = {
            endereco: '',
            origin: 'Rua doutor augusto de miranda 57',
            destination: 'Avenida Paulista 1100',
            preco: 0,
            pegarEncomenda: 0,
            cartItems: []
        };

        this.myRef = React.createRef();

        this.mudarEndereco = this.mudarEndereco.bind(this);
        this.calculaDistancia = this.calculaDistancia.bind(this);
        this.pegarEncomenda = this.pegarEncomenda.bind(this);
    }

    componentWillMount() {
        if (localStorage.getItem('cartItems')) {
            this.setState({ cartItems: JSON.parse(localStorage.getItem('cartItems')) });
          }

          localStorage.setItem('pegarencomenda', '0');
      
          fetch('db.json').then(res => res.json()).then(data => data.products)
            .then(data => {
              this.setState({ products: data });
            });
    }

    componentDidMount() {
        var options = {
            componentRestrictions: {
                country: "br"
            }
        };


        const autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(this.myRef.current),
            {options});

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            
            console.log('Eita lecao', place.formatted_address);

            this.calculaDistancia(place.formatted_address);
        });
    }

    mudarEndereco(event) {
        this.setState({
            endereco: event.target.value,
        });
    }

    calculaDistancia(formattedAddress) {
            console.log('Calculando');
            const DistanceMatrixService = new window.google.maps.DistanceMatrixService(); 
        
            DistanceMatrixService.getDistanceMatrix({
                origins: ['Rua doutor augusto de miranda, 57'],
                destinations: [formattedAddress],
                travelMode: 'DRIVING',
                }, (response, status) => {
                    if(status === 'OK') {
                        if(response  && response.rows[0] &&  response.rows[0].elements[0].distance && response.rows[0].elements[0].distance.value) {
                            const distancia = response.rows[0].elements[0].distance.value / 1000;
                            const preco = (distancia * 4.5 < 55 ? 55 : (distancia * 4.5).toFixed(2));
                            localStorage.setItem('preco', preco);
                            localStorage.setItem('endereco', formattedAddress);
                            this.setState({
                                distancia: response.rows[0].elements[0].distance.value / 1000,
                                preco,
                            });
                        }
                    }
                });
    }

    pegarEncomenda() {
        localStorage.setItem('pegarencomenda', '1');
        this.setState( {
            pegarEncomenda: 1,
        });
    }
    

    render() {

        const { cartItems } = this.state;

        if (this.state.pegarEncomenda === 1) {
            return <Redirect to='/checkout' />
          }


        return (
            <div id="shipping">
                <h3>Calcular preço da entrega</h3>

                <div class="shipping-form">
                    <form method="GET" action="/">
                        <label for="endereco-entrega">Endereco de entrega:</label>
                       

                        <input placeholder="DIGITE O CEP" ref={this.myRef} value={this.state.endereco} onChange={this.mudarEndereco} type="text" className="input-entrega" name="input-entrega" />

                        {/* <Autocomplete className="input-encomenda"
                        value={this.state.endereco} onChange={this.mudarEndereco}
                            onPlaceSelected={this.mudarEndereco}
                            types={['address']}
                            componentRestrictions={{country: "br"}}
                        /> */}

                        <div class="preco-encomenda">
                            {
                                this.state.distancia ?
                                <div className="preco-final">
                                    Frete: R${this.state.preco}
                                </div>
                                        
                                : ''
                            }
                            <p className="preco-ceia">Ceia: {util.formatCurrency(cartItems.reduce((a, c) => (a + c.price * c.count), 0))}</p>
                        </div>
                        
                        {
                                // true ?
                                this.state.distancia ?
                                <p>
                                    Caso queira buscar a encomenda, na Rua Doutor Augusto de Miranda 57 - Pompéia - <span className="buscar-encomenda" onClick={this.pegarEncomenda}>(Clique aqui)</span>
                                </p>
                                : ''
                        }

                        {this.state.distancia &&
                         <Link className="botao-finalizar finalizar" to="/checkout">Finalizar Compra</Link>}
                    </form>

                    
                </div>
            </div>
        ) 

        ;
    }
}

export default Shipping;